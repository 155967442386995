window.jQuery = window.$ = require("jquery");
import Swiper from "swiper";
export function initSlider() {
  const swiperSlider = $(".swiper-slider-secondary");

  let index = 1;
  swiperSlider.each(function() {
    var carouselElement = $(this);
    var cols = $(this).data("cols");
    var loop = $(this).data("loop");
    var autoplay = $(this).data("autoplay");
    const delaySpeed = $(this).data("delay");
    var delay = delaySpeed != null && delaySpeed !== "" ? delaySpeed : 5000;
    var speed = 600;
    var val_nav = $(this).data("arrows");
    var nav_arrow = $(this).data("arrows-class");
    var val_dots = $(this).data("dots");
    var val_center = $(this).data("center");
    var style = $(this).data("effect");
    var loopSlide = null;

    carouselElement.addClass("pbmit-element-viewtype-carousel-" + index);

    let responsiveItems = ["1", "1", "1", "1", "1"];
    if (cols === 2) {
      responsiveItems = ["2", "2", "2", "2", "1"];
    } else if (cols === 3) {
      responsiveItems = ["3", "2", "2", "2", "1"];
    } else if (cols === 4) {
      responsiveItems = ["4", "4", "4", "2", "1"];
    } else if (cols === 5) {
      responsiveItems = ["5", "4", "3", "2", "1"];
    } else if (cols === 6) {
      responsiveItems = ["6", "4", "3", "2", "1"];
    } else if (cols > 6) {
      responsiveItems = ["3", "3", "3", "2", "1"];
    }

    if (val_dots === true) {
      carouselElement.append(
        '<div class="swiper-pagination swiper-pagination"></div>'
      );
    }

    if (val_nav === true) {
      if (!nav_arrow) {
        carouselElement.append('<div class="swiper-buttons"></div>');
        carouselElement
          .find(".swiper-buttons")
          .append(
            '<div class="swiper-button-next swiper-button-next-' +
              index +
              '"></div>'
          );
        carouselElement
          .find(".swiper-buttons")
          .append(
            '<div class="swiper-button-prev swiper-button-prev-' +
              index +
              '"></div>'
          );
      } else {
        const arrowNavSelector = "." + nav_arrow;
        $(arrowNavSelector).append('<div class="swiper-buttons"></div>');
        $(arrowNavSelector).append(
          '<div class="swiper-button-next swiper-button-next-' +
            index +
            '"></div>'
        );
        $(arrowNavSelector).append(
          '<div class="swiper-button-prev swiper-button-prev-' +
            index +
            '"></div>'
        );
      }
    }

    var pagination_val = false;
    if (val_dots === true) {
      pagination_val = {
        el: ".swiper-pagination",
        clickable: true
      };
    }
    var navigation_val = false;
    if (val_nav === true) {
      navigation_val = {
        nextEl: ".swiper-button-next-" + index,
        prevEl: ".swiper-button-prev-" + index
      };
    }

    if (!style) {
      style = "slide";
    }

    const marginItem = $(carouselElement).data("margin");
    const margin_val =
      marginItem !== "" || parseInt(marginItem, 10) !== 0 ? marginItem : 30;

    console.log(">>> cols", cols, responsiveItems);

    new Swiper(".pbmit-element-viewtype-carousel-" + index, {
      loop,
      autoplay: {
        delay,
        disableOnInteraction: true,
        speed
      },
      delay,
      navigation: navigation_val,
      pagination: pagination_val,
      slidesPerView: cols,
      spaceBetween: margin_val,
      loopedSlides: loopSlide,
      effect: style,
      speed,
      grabCursor: false,
      centeredSlides: val_center,
      breakpoints: {
        1199: {
          slidesPerView: responsiveItems[0]
        },
        991: {
          slidesPerView: responsiveItems[1]
        },
        767: {
          slidesPerView: responsiveItems[2]
        },
        575: {
          slidesPerView: responsiveItems[3]
        },
        0: {
          slidesPerView: responsiveItems[4]
        }
      }
    });
    index = index + 1;
  });
}

export default initSlider;
