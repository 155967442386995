export const apiRequest = async (url, request, token = null, method) => {
  const tokenElement = document.querySelector('meta[name="_token"]')?.content;

  let headers = {
    "X-CSRF-TOKEN": token || tokenElement
  };
  console.log(">>> request.headers", request.headers);

  if (!request?.headers?.["Content-Type"]) {
    headers["Content-Type"] = "application/json";
  }

  const body = request?.params || null;

  if (request?.headers) {
    headers = {
      ...headers,
      ...request?.headers
    };
  }

  const isMultipart = headers["Content-Type"].startsWith("multipart/form-data");

  const response = await fetch(url, {
    method,
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers,
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: isMultipart ? body : JSON.stringify(body)
  });

  return await response.json();
};

export const $api = {
  get: async function(url, request, token) {
    return apiRequest(url, request, token, "GET");
  },
  post: async function(url, request, token) {
    return apiRequest(url, request, token, "POST");
  },
  put: async function(url, request, token) {
    return apiRequest(url, request, token, "PUT");
  },
  patch: async function(url, request, token) {
    return apiRequest(url, request, token, "PATCH");
  },
  delete: async function(url, request, token) {
    return apiRequest(url, request, token, "DELETE");
  }
};
