import { $api } from "../../../utils/api";
import { getLanguageId } from "../../../utils/utils";

export const RenderVacancies = async ({
  address = null,
  search = null,
  isRemote = null,
  categoryIds = [],
  page = 0,
  clear = false
}) => {
  const container = document.getElementById("job-listing");
  if (!container) return;

  const location =
    address == null
      ? document.getElementById("search_location")?.value
      : address;

  const keyword =
    search == null ? document.getElementById("search_keywords")?.value : search;

  const remote =
    isRemote == null
      ? document.querySelector("input#remote_position:checked")?.value
      : isRemote;

  const currentCategories =
    !categoryIds || !categoryIds?.length
      ? document.querySelectorAll("#job-types input:checked")
      : categoryIds;

  const filters =
    currentCategories != null
      ? Array.from(currentCategories).map(x => x?.value)
      : [];
  const languageId = getLanguageId();

  // console.log(">>> params", {
  //   location,
  //   keyword,
  //   remote,
  //   filters,
  //   page
  // });

  const request = await $api.post(`/${languageId}/vacancies/fetch`, {
    params: {
      location,
      keyword,
      remote,
      filters,
      page
    }
  });

  const currentPage = request?.pagination?.current_page || 0;
  const lastPage = request?.pagination?.last_page;

  const data = request?.data?.data || [];

  toggleLoadMoreButton(currentPage, lastPage);

  const jobListings = document.getElementById("job-listing");

  const currentUrl = `${window.location.origin}${window.location.pathname}`;
  // console.log(">>> current url", currentUrl);

  if (clear) {
    jobListings.innerHTML = "";
  }

  data.forEach(item => {
    const metas = item?.meta != null ? JSON.parse(item.meta) : null;
    const category =
      item?.pages && item?.pages?.length ? item.pages[0].title : null;

    const companyName = metas?.company_name
      ? `<strong>${metas.company_name}</strong>`
      : "";
    const locationName = item?.description ? item.description : "";
    const categoryName = category?.title ? category.title : "";
    const imageSrc = item?.cover_photo?.url ? item.cover_photo.url : null;
    const imageAlt = item?.cover_photo?.name
      ? item.cover_photo.name
      : item?.title;
    const daysAgo = item?.days_ago ? item.days_ago : "";

    jobListings.innerHTML += `
      <li class="post-15186 job_listing type-job_listing status-publish has-post-thumbnail hentry job-type-internship" data-longitude="" data-latitude="" style="visibility: visible;">
        <a href="${currentUrl}/${item.item_id}-${item.slug}">
            <img class="company_logo" src="${imageSrc}" alt="${imageAlt}">

            <div class="position">
              <h3>${item.title}</h3>

              <div class="company">${companyName}</div>
            </div>


            <ul class="meta">
              <li class="job-type internship">${categoryName}</li>
              ${locationName}
            </ul>
        </a>
      </li>
    `;
    // <li class="date">
    //                 <time datetime="${item.date}">
    //                   ${daysAgo}
    //                 </time>
    //               </li>
  });
};

const toggleLoadMoreButton = (currentPage, lastPage) => {
  const loadMoreJobsButton = document.getElementById("load-more-jobs");
  if (loadMoreJobsButton) {
    if (currentPage < lastPage) {
      loadMoreJobsButton.classList.remove("force-hide");
    } else {
      loadMoreJobsButton.classList.add("force-hide");
    }

    loadMoreJobsButton.setAttribute("data-page", currentPage);
  }
};

export const FetchVacancies = () => {
  const searchVacancyButton = document.querySelector(
    "#search-vacancy-button input"
  );

  if (searchVacancyButton) {
    searchVacancyButton.addEventListener("click", () => {
      RenderVacancies({ clear: true });
    });
  }

  const loadMoreJobsButton = document.getElementById("load-more-jobs");

  if (loadMoreJobsButton) {
    loadMoreJobsButton.addEventListener("click", () => {
      const currentPage = loadMoreJobsButton.getAttribute("data-page") || 0;
      const page = parseInt(currentPage, 10) + 1;

      RenderVacancies({ page });
    });
  }

  const remoteInput = document.getElementById("remote_position");

  if (remoteInput) {
    remoteInput.addEventListener("click", () => {
      RenderVacancies({ clear: true });
    });
  }

  const typeInputs = document.querySelectorAll("#job-types input");

  if (typeInputs && typeInputs.length) {
    typeInputs.forEach(item => {
      item.addEventListener("click", () => {
        RenderVacancies({ clear: true });
      });
    });
  }
};
