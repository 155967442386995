import { $api } from "../utils/api";
import {
  emailRegex,
  getLanguageId,
  isNullOrEmpty,
  phoneRegex,
  setResult,
  toggleBorder
} from "../utils/utils";

export function ApplicationForm() {
  const formButton = document.getElementById("application-form-button");

  if (formButton) {
    formButton.addEventListener("click", async () => {
      if (formButton.getAttribute("processing") === "true") {
        return;
      }

      formButton.setAttribute("processing", "true");

      const inputs = document.querySelectorAll(
        "#application-form input, #application-form textarea"
      );

      const invalidInputs = [];
      const attributeName = "required";
      const params = {};
      inputs.forEach(item => {
        if (
          item.hasAttribute(attributeName) &&
          item.getAttribute(attributeName) === attributeName
        ) {
          const invalid = isNullOrEmpty(item);
          if (invalid) {
            invalidInputs.push(item);
            setResult("#application-form-error", undefined, true);
            // console.log(item.getAttribute("name"), item.value);
          }
        }

        if (item.getAttribute("type") === "checkbox") {
          const element = item?.parentElement?.querySelector("span");
          toggleBorder(element, item.checked === true);
        }

        if (item.getAttribute("type") === "email") {
          const valid = !!emailRegex(item.value);
          toggleBorder(item, valid);
          if (!valid) {
            invalidInputs.push(item);
            setResult("#application-form-mobile-or-email", undefined, true);
          }
        }

        if (item.getAttribute("type") === "tel") {
          const valid = !!phoneRegex(item.value);
          toggleBorder(item, valid);
          if (!valid) {
            invalidInputs.push(item);
            setResult("#application-form-mobile-or-email", undefined, true);
          }
        }

        params[item.getAttribute("name")] = item.value;
      });

      window.dataLayer = window?.dataLayer || [];
      window.dataLayer.push({
        event: "merito-application",
        ...params
      });

      if (!invalidInputs.length) {
        const token = document.querySelector(
          '#application-form input[name="_token"]'
        )?.value;

        const languageId = getLanguageId() || "ge";
        const data = await $api.post(
          `/${languageId}/merito/applications/submit`,
          { params },
          token
        );

        console.log(">>> data", data);
        setResult("#application-form-mobile-or-email", undefined, false);
        setResult("#application-form-error", undefined, false);
        setResult("#application-form-result", data.status, true);

        inputs.forEach(item => {
          item.value = "";
        });
      }

      formButton.setAttribute("processing", "false");
    });
  }
}
