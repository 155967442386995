export function emailRegex(email) {
  const regex = new RegExp(
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/gim
  );

  return regex.test(email);
}

export function phoneRegex(phone) {
  const regex = new RegExp(
    /^(?:(?:\d{0,3})\d{0,3})\d{2,3}\d{2,3}\d{2,3}\d{0,3}/gim
  );

  return regex.test(phone);
}

export function toggleBorder(
  element,
  allowed = true,
  borderColor = "#212122",
  color = "#212122"
) {
  const calculatedColors = allowed ? borderColor : "crimson";
  if (element != null && element?.style) {
    element.style.borderColor = calculatedColors;
    element.style.color = allowed ? color || calculatedColors : "crimson";
  }

  return allowed;
}

export function isNullOrEmpty(element) {
  if (element.value === "" || element.value == null) {
    return !toggleBorder(element, false);
  }

  return !toggleBorder(element, true);
}

export function setResult(selector = "#", result = undefined, display = false) {
  const element = document.querySelector(selector);

  if (element) {
    if (result !== undefined) {
      element.innerHTML = result;
    }
    element.style.display = display ? "block" : "none";
  }
}

export const getLanguageId = () => {
  return document
    .querySelector('meta[name="language-id"]')
    ?.getAttribute("content");
};
