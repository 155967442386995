export const apiRequest = async (url, request, token = null, method) => {
  const tokenElement = document.querySelector('meta[name="_token"]')?.content;

  let headers = {
    "X-CSRF-TOKEN": token || tokenElement,
    "Content-Type": "application/json"
  };

  const body = request?.params || null;

  if (request?.header) {
    headers = {
      ...headers,
      ...request?.header
    };
  }

  const response = await fetch(url, {
    method,
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers,
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: JSON.stringify(body)
  });

  return await response.json();
};

export const $api = {
  get: async function(url, request, token) {
    return apiRequest(url, request, token, "GET");
  },
  post: async function(url, request, token) {
    return apiRequest(url, request, token, "POST");
  },
  put: async function(url, request, token) {
    return apiRequest(url, request, token, "PUT");
  },
  patch: async function(url, request, token) {
    return apiRequest(url, request, token, "PATCH");
  },
  delete: async function(url, request, token) {
    return apiRequest(url, request, token, "DELETE");
  }
};
