import { $api } from "../utils/api";
import {
  emailRegex,
  getLanguageId,
  isNullOrEmpty,
  setResult
} from "../utils/utils";

export function ContactForm() {
  const formButton = document.getElementById("contact-form-button");

  if (formButton) {
    formButton.addEventListener("click", async () => {
      if (formButton.getAttribute("processing") === "true") {
        return;
      }

      formButton.setAttribute("processing", "true");

      const emailContainer = document.getElementById("contact-email");
      const nameContainer = document.getElementById("contact-name");
      const phoneContainer = document.getElementById("contact-phone");
      const companyNameContainer = document.getElementById(
        "contact-company-name"
      );
      const textContainer = document.getElementById("contact-text");

      const name = nameContainer?.value;
      const email =
        emailContainer?.value != null ? emailContainer?.value.trimEnd() : null;
      const phone = phoneContainer?.value;
      const companyName = companyNameContainer?.value;
      const text = textContainer?.value;
      const emptyEmailAlert = document.getElementById("email-empty");
      const incorrectEmailAlert = document.getElementById("email-incorrect");
      const fillAllFields = document.getElementById("fill-all-fields");
      const contactFormResultContainer = document.getElementById(
        "contact-form-result-container"
      );

      emptyEmailAlert.classList.add("force-hide");
      incorrectEmailAlert.classList.add("force-hide");
      fillAllFields.classList.add("force-hide");
      contactFormResultContainer.classList.add("force-hide");

      const allowed = emailRegex(email);
      const emailIsNotEmpty = isNullOrEmpty(emailContainer);
      const nameIsNotEmpty = isNullOrEmpty(nameContainer);
      const textIsNotEmpty = isNullOrEmpty(textContainer);

      if (nameIsNotEmpty || textIsNotEmpty) {
        fillAllFields.classList.remove("force-hide");
        formButton.setAttribute("processing", "false");
        return;
      }

      if (allowed && !emailIsNotEmpty) {
        const token = document.querySelector(
          '#contact-form input[name="_token"]'
        )?.value;

        const languageId = getLanguageId() || "ge";
        const data = await $api.post(
          `/${languageId}/form/send`,
          {
            params: {
              email,
              name,
              phone,
              companyName,
              text
            }
          },
          token
        );

        setResult("#contact-form-result", data.status, true);
        contactFormResultContainer.classList.remove("force-hide");
        emailContainer.value = "";
        nameContainer.value = "";
        phoneContainer.value = "";
        companyNameContainer.value = "";
        textContainer.value = "";
      } else if (emailIsNotEmpty) {
        emptyEmailAlert.classList.remove("force-hide");
      } else if (!allowed) {
        incorrectEmailAlert.classList.remove("force-hide");
        // setResult("#contact-form-result");
      }

      formButton.setAttribute("processing", "false");
    });
  }
}
