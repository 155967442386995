import { $api } from "../utils/api";
import { emailRegex, getLanguageId, setResult } from "../utils/utils";

export function Subscribe() {
  const subscriptionButton = document.getElementById("subscribe-to-newsletter");

  if (subscriptionButton) {
    subscriptionButton.addEventListener("click", async () => {
      if (subscriptionButton.getAttribute("processing") === "true") {
        return;
      }

      subscriptionButton.setAttribute("processing", "true");

      const email = document.getElementById("subscribe-email");
      console.log(">>> email", email);
      const allowed = emailRegex(email.value);

      const calculatedColors = allowed ? "#212122" : "crimson";

      email.style.borderColor = calculatedColors;
      email.style.color = calculatedColors;
      console.log(">>> allowed", allowed);

      if (allowed) {
        const token = document.querySelector(
          '#subscription-form input[name="_token"]'
        )?.value;

        const data = await $api.post(
          `/${getLanguageId()}/subscribe`,
          {
            params: {
              email: email.value
            }
          },
          token
        );

        setResult("#subscription-result", data.status, true);
        email.value = "";
      } else {
        setResult("#subscription-result", "");
      }

      subscriptionButton.setAttribute("processing", "false");
    });
  }
}
