window.jQuery = window.$ = require("jquery");
const popper = require("./theme/js/popper.min");
const bootstrap = require("./theme/js/bootstrap.min");
require("./theme/js/jquery.waypoints.min");
require("./theme/js/jquery.appear");
require("./theme/js/numinate.min");
const circle = require("./theme/js/circle-progress");
require("./theme/js/jquery.magnific-popup.min");
import { Subscribe } from "./subscribe";
import { ContactForm } from "./contact-form";
import { ApplicationForm } from "./application-form";
import AOS from "./theme/js/aos";
console.log(">>> popper", popper);
console.log(">>> bootstrap", bootstrap);
console.log(">>> circle", circle);
require("./theme/revolution/rslider");
require("./theme/revolution/rbtools.min");
require("./theme/revolution/rs6.min");
require("./theme/js/jquery-ui.min");
import Swiper from "swiper";
import SimpleLightbox from "simplelightbox";
import { FetchVacancies, RenderVacancies } from "./theme/js/vacancies";
import { initSlider } from "./swiper";

const copyButton = document.querySelector("#copyButton > a > i");

if (copyButton) {
  copyButton.addEventListener("click", function() {
    const copyText = document.querySelector("#copyButton > a > input");

    if (copyText) {
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      navigator.clipboard.writeText(copyText.value);
    }
  });
}

function setHeightToContent(selectors) {
  const projects = document.querySelectorAll(selectors);

  if (projects && projects?.length) {
    for (let project of projects) {
      project.style.height = `${project.offsetWidth}px`;
    }
  }
}

setHeightToContent(".pbmit-portfolio-style-1");
setHeightToContent(
  ".pbmit-portfolio-style-2.pbmit-odd .pbmit-image-wrapper .pbmit-featured-wrapper"
);
setHeightToContent(
  ".blog-grid .pbmit-blogbox-style-1 .pbmit-featured-container .pbmit-featured-wrapper"
);
setHeightToContent(
  ".our-advisers .pbminfotech-post-item .pbminfotech-team-image-box .pbmit-featured-wrapper"
);
setHeightToContent(".about-us-team-section .pbmit-featured-wrapper");
setHeightToContent(".jury--item");

// Calculate Expert Image Height
function calculateExpertImageHeight() {
  const container = document.querySelector(
    ".service-sidebar .widget-as-link .textwidget.brochure-image"
  );

  if (container) {
    container.style.height = `${container.offsetWidth}px`;
  }
}

calculateExpertImageHeight();

function testimonialButtons() {
  const testimonialButtons = document.querySelectorAll(".pbminfotech-box-btn");
  for (let item of testimonialButtons) {
    const container = item?.parentNode?.parentNode;
    const className = "pbminfotech-testimonial-text-see-more";

    item.addEventListener("click", function() {
      if (container) {
        container.classList.toggle(className);
      }
    });
  }
}

testimonialButtons();

// Initialize Subscribe functionality
Subscribe();
ContactForm();
ApplicationForm();
RenderVacancies({ clear: true });
FetchVacancies();

const showVacancyButton = document.getElementById("show-vacancy-email");
if (showVacancyButton) {
  showVacancyButton.addEventListener("click", () => {
    const vacancyEmailContainer = document.getElementById(
      "job-application-email"
    );
    if (vacancyEmailContainer) {
      vacancyEmailContainer?.classList?.remove("force-hide");
    }
  });
}

if (window) {
  window.onresize = () => {
    setHeightToContent(".pbmit-portfolio-style-1");
    setHeightToContent(
      ".pbmit-portfolio-style-2.pbmit-odd .pbmit-image-wrapper .pbmit-featured-wrapper"
    );
    setHeightToContent(
      ".blog-grid .pbmit-blogbox-style-1 .pbmit-featured-container .pbmit-featured-wrapper"
    );
    setHeightToContent(
      ".our-advisers .pbminfotech-post-item .pbminfotech-team-image-box .pbmit-featured-wrapper"
    );
    setHeightToContent(".about-us-team-section .pbmit-featured-wrapper");
    calculateExpertImageHeight();
  };
}
$(document).ready(function($) {
  "use strict";

  new SimpleLightbox(".awards--gallery a.awards--gallery-image", {
    scrollZoom: false,
    uniqueImages: false,
    captions: false,
    overlayOpacity: 0.9,
    showCounter: false,
    animationSlide: true,
    animationSpeed: 250,
    fadeSpeed: 0,
    doubleTapZoom: 0,
    navText: [
      '<span class="fa fa-long-arrow-left"></span>',
      '<span class="fa fa-long-arrow-right"></span>'
    ]
  });

  $("#application-project-start-date").datepicker({
    dateFormat: "dd-mm-yy"
  });

  $("#application-project-end-date").datepicker({
    dateFormat: "dd-mm-yy"
  });

  /*-------------------------------------
            Animation on scroll: Number rotator
        -------------------------------------*/
  $("[data-appear-animation]").each(function() {
    const self = $(this);
    var animation = self.data("appear-animation");
    var delay = self.data("appear-animation-delay")
      ? self.data("appear-animation-delay")
      : 0;

    if ($(window).width() > 959) {
      self.html("0");
      self.waypoint(
        function(direction) {
          if (!self.hasClass("completed")) {
            var from = self.data("from");
            var to = self.data("to");
            var interval = self.data("interval");
            self.numinate({
              format: "%counter%",
              from: from,
              to: to,
              runningInterval: 2000,
              stepUnit: interval,
              onComplete: function(elem) {
                self.addClass("completed");
              }
            });
          }
        },
        { offset: "85%" }
      );
    } else {
      self.html(self.data("to"));
    }
  });

  initSlider();

  /*-------------------------------------
        Swiper Slider
        -------------------------------------*/
  var swiperslider = $(".swiper-slider");
  var x = 1;
  swiperslider.each(function() {
    var carouselElement = $(this);
    var columns = $(this).data("columns");
    var loop = $(this).data("loop");
    var autoplay = $(this).data("autoplay");
    const delaySpeed = $(this).data("delay");
    var delay = delaySpeed != null && delaySpeed !== "" ? delaySpeed : 5000;
    var speed = 600;
    var val_nav = $(this).data("arrows");
    var nav_arrow = $(this).data("arrows-class");
    var val_dots = $(this).data("dots");
    var val_center = $(this).data("center");
    var style = $(this).data("effect");
    var loopSlide = null;
    var sl_speed = 300;

    carouselElement.addClass("pbmit-element-viewtype-carousel-" + x);

    let responsive_items = [
      /* 1199 : */ "1",
      /* 991 : */ "1",
      /* 767 : */ "1",
      /* 575 : */ "1",
      /* 0 : */ "1"
    ];

    if (columns === 2) {
      responsive_items = [
        /* 1199 : */ "2",
        /* 991 : */ "2",
        /* 767 : */ "2",
        /* 575 : */ "2",
        /* 0 : */ "1"
      ];
    } else if (columns === 3) {
      responsive_items = [
        /* 1199 : */ "3",
        /* 991 : */ "2",
        /* 767 : */ "2",
        /* 575 : */ "2",
        /* 0 : */ "1"
      ];
    } else if (columns === 4) {
      responsive_items = [
        /* 1199 : */ "4",
        /* 991 : */ "4",
        /* 767 : */ "3",
        /* 575 : */ "2",
        /* 0 : */ "1"
      ];
    } else if (columns === 5) {
      responsive_items = [
        /* 1199 : */ "5",
        /* 991 : */ "4",
        /* 767 : */ "3",
        /* 575 : */ "2",
        /* 0 : */ "1"
      ];
    } else if (columns === 6) {
      responsive_items = [
        /* 1199 : */ "6",
        /* 991 : */ "5",
        /* 767 : */ "4",
        /* 575 : */ "3",
        /* 0 : */ "1"
      ];
    } else if (columns > 6) {
      responsive_items = [
        /* 1199 : */ "3",
        /* 991 : */ "3",
        /* 767 : */ "3",
        /* 575 : */ "2",
        /* 0 : */ "1"
      ];
    }

    if (val_dots === true) {
      carouselElement.append(
        '<div class="swiper-pagination swiper-pagination"></div>'
      );
    }

    if (val_nav === true) {
      if (!nav_arrow) {
        carouselElement.append('<div class="swiper-buttons"></div>');
        carouselElement
          .find(".swiper-buttons")
          .append(
            '<div class="swiper-button-next swiper-button-next-' +
              x +
              '"></div>'
          );
        carouselElement
          .find(".swiper-buttons")
          .append(
            '<div class="swiper-button-prev swiper-button-prev-' +
              x +
              '"></div>'
          );
      } else {
        $("." + nav_arrow).append('<div class="swiper-buttons"></div>');
        $("." + nav_arrow).append(
          '<div class="swiper-button-next swiper-button-next-' + x + '"></div>'
        );
        $("." + nav_arrow).append(
          '<div class="swiper-button-prev swiper-button-prev-' + x + '"></div>'
        );
      }
    }

    let pagination_val = false;
    if (val_dots === true) {
      pagination_val = {
        el: ".swiper-pagination",
        clickable: true
      };
    }
    let navigation_val = false;
    if (val_nav === true) {
      navigation_val = {
        nextEl: ".swiper-button-next-" + x,
        prevEl: ".swiper-button-prev-" + x
      };
    }

    if (!style) {
      style = "slide";
    }

    let margin_val = 30;
    if (
      $(carouselElement).data("margin") !== "" ||
      $(carouselElement).data("margin") == "0"
    ) {
      margin_val = $(carouselElement).data("margin");
    }
    if (carouselElement.hasClass("marquee")) {
      let reverseDirection = $(this).data("reverse");
      if (!reverseDirection) reverseDirection = false;
      const swiper = new Swiper(".pbmit-element-viewtype-carousel-" + x, {
        spaceBetween: 0,
        centeredSlides: true,
        speed,
        delay,
        autoplay: {
          delay,
          disableOnInteraction: true,
          reverseDirection,
          speed
        },
        loop: true,
        slidesPerView: "auto",
        allowTouchMove: false,
        disableOnInteraction: true
      });
    } else {
      const slidesArray = Array.from(
        $(this)
          .children()
          .children()
      );
      const slidesLength = slidesArray.length;
      const width = window.innerWidth;
      let slidesCount = responsive_items[0];
      if (width < 1199 && width > 991) {
        slidesCount = responsive_items[1];
      } else if (width > 767) {
        slidesCount = responsive_items[2];
      } else if (width > 575) {
        slidesCount = responsive_items[3];
      } else if (width < 0) {
        slidesCount = responsive_items[4];
      }

      const swiper = new Swiper(".pbmit-element-viewtype-carousel-" + x, {
        loop,
        autoplay: {
          delay,
          disableOnInteraction: true,
          speed
        },
        delay,
        navigation: navigation_val,
        pagination: pagination_val,
        slidesPerView: columns,
        spaceBetween: margin_val,
        loopedSlides: loopSlide,
        effect: style,
        speed,
        grabCursor: false,
        centeredSlides: slidesLength - 2 < slidesCount ? val_center : false,
        breakpoints: {
          1199: {
            slidesPerView: responsive_items[0]
          },
          991: {
            slidesPerView: responsive_items[1]
          },
          767: {
            slidesPerView: responsive_items[2]
          },
          575: {
            slidesPerView: responsive_items[3]
          },
          0: {
            slidesPerView: responsive_items[4]
          }
        }
      });
    }
    x = x + 1;
  });

  /*-------------------------------------
        ProgressBar
        -------------------------------------*/
  AOS.init({
    once: true
  });
  /*-------------------------------------
        Scroll To Top
        -------------------------------------*/
  // $("body").append(
  //   '<a href="#" class="scroll-to-top"><i class="pbmit-base-icon-arrow-right"></i></a>'
  // );
  const btn = $(".scroll-to-top");
  $(window).scroll(function() {
    if ($(window).scrollTop() > 300) {
      btn.addClass("show");
    } else {
      btn.removeClass("show");
    }
  });
  btn.on("click", function(e) {
    e.preventDefault();
    $("html, body").animate({ scrollTop: 0 }, "300");
  });

  /*-------------------------------------
        Header Search Form
        -------------------------------------*/
  $(".pbmit-header-search-btn a").on("click", function() {
    $(".pbmit-search-overlay").addClass("st-show");
    $("body").addClass("st-prevent-scroll");
    return false;
  });
  $(".pbmit-icon-close").on("click", function() {
    $(".pbmit-search-overlay").removeClass("st-show");
    $("body").removeClass("st-prevent-scroll");
    return false;
  });
  $(".pbmit-site-searchform").on("click", function(event) {
    event.stopPropagation();
  });

  const tooltipTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="tooltip"]')
  );

  const tooltipList = tooltipTriggerList.map(function(tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl);
  });
  /*-------------------------------------
        Magnific Popup
        -------------------------------------*/
  $(".pbmit-lightbox-video, .pbmit-lightbox-video a, a.pbmit-lightbox").each(
    function() {
      const type = $(this).hasClass("pbmit-lightbox-video")
        ? "iframe"
        : "image";
      // if ($(this).hasOwnProperty("magnificPopup"))
      $(this)?.magnificPopup({ type });
    }
  );

  /*-------------------------------------
         Accordion
        -------------------------------------*/

  $(".accordion .accordion-item").on("click", function() {
    $(this)
      .parent()
      .find(".accordion-item")
      .removeClass("active");
    if (
      !$(this)
        .find(".accordion-button")
        .hasClass("collapsed")
    ) {
      $(this).addClass("active");
    }
  });

  /*-------------------------------------
         Tab
        -------------------------------------*/

  $(".nav-tabs  .nav-item").on("click", function() {
    $(this)
      .parent()
      .find(".tabactive")
      .removeClass("tabactive");
    $(this).addClass("tabactive");
  });

  /*-------------------------------------
          Add plus icon in menu
          -------------------------------------*/
  $(".main-menu ul.navigation li.dropdown").append(
    "<span class='righticon'><i class='fa fa-chevron-down'></i></span>"
  );

  /*-------------------------------------
        Responsive Menu
        -------------------------------------*/
  $(".main-menu ul.navigation li.dropdown .righticon").on("click", function() {
    $(this)
      .siblings()
      .toggleClass("open");

    $(this)
      .find("i")
      .toggleClass("fa-chevron-down fa-chevron-up");
    return false;
  });

  $(".main-menu ul.navigation li.dropdown .dropdown-sibling").on(
    "click",
    function(e) {
      $(this)
        .siblings()
        .toggleClass("open");

      // $(this)
      //   .children("ul")
      //   .toggleClass("open");

      $(this)
        .find("i")
        .toggleClass("fa-chevron-down fa-chevron-up");
      return false;
    }
  );

  /*-------------------------------------
        Sticky Header
        -------------------------------------*/
  $(window).scroll(function() {
    var sticky = $(".site-header-menu-2"),
      scroll = $(window).scrollTop();
    if (scroll >= 90) sticky.addClass("sticky-header");
    else sticky.removeClass("sticky-header");
  });

  /*-------------------------------------
        Circle Progressbar
        -------------------------------------*/
  $(".pbmit-circle-outer").each(function() {
    var this_circle = $(this);

    // Circle settings
    var emptyFill_val = "rgba(0, 0, 0, 0)";
    var thickness_val = 10;
    var fill_val = this_circle.data("fill");
    var size_val = 110;

    if (
      typeof this_circle.data("emptyfill") !== "undefined" &&
      this_circle.data("emptyfill") !== ""
    ) {
      emptyFill_val = this_circle.data("emptyfill");
    }
    if (
      typeof this_circle.data("thickness") !== "undefined" &&
      this_circle.data("thickness") !== ""
    ) {
      thickness_val = this_circle.data("thickness");
    }
    if (
      typeof this_circle.data("size") !== "undefined" &&
      this_circle.data("size") !== ""
    ) {
      size_val = this_circle.data("size");
    }
    if (
      typeof this_circle.data("filltype") !== "undefined" &&
      this_circle.data("filltype") === "gradient"
    ) {
      fill_val = {
        gradient: [
          this_circle.data("gradient1"),
          this_circle.data("gradient2")
        ],
        gradientAngle: Math.PI / 4
      };
    }

    if (typeof $.fn.circleProgress === "function") {
      var digit = this_circle.data("digit");
      var before = this_circle.data("before");
      var after = this_circle.data("after");
      var digit = Number(digit);
      var short_digit = digit / 100;

      $(".pbmit-circle", this_circle)
        .circleProgress({
          value: 0,
          size: size_val,
          startAngle: (-Math.PI / 4) * 2,
          thickness: thickness_val,
          emptyFill: emptyFill_val,
          fill: fill_val
        })
        .on("circle-animation-progress", function(event, progress, stepValue) {
          // Rotate number when animating
          this_circle
            .find(".pbmit-circle-number")
            .html(before + Math.round(stepValue * 100) + after);
        });
    }

    this_circle.waypoint(
      function(direction) {
        if (!this_circle.hasClass("completed")) {
          // Re draw when view
          if (typeof $.fn.circleProgress === "function") {
            $(".pbmit-circle", this_circle).circleProgress({
              value: short_digit
            });
          }
          this_circle.addClass("completed");
        }
      },
      { offset: "85%" }
    );
  });
});
